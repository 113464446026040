import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "bootstrap";

import ScrollToTop from "./utility/ScrollToTop";
import SwitchController from "./utility/SwitchController";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import pageStructure from "./pages/Pages";

import ReactGA from 'react-ga';

const TRACKING_ID = "G-ZDK2D6LWE1";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <Router>
      <ScrollToTop />


      <div className="container-fluid p-0">
        <Header />

        <Suspense fallback="">
          <div id="container-main">
            <SwitchController structure={pageStructure} />

            <Footer />
          </div>
        </Suspense>
      </div>

    </Router>
  );
}

export default App;
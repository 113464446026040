import React from 'react';

import "./Footer.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div id="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <h5>Våra tjänster</h5>
            <ul className="link-col">
              <li><Link to="/privatlan">Privatlån</Link></li>
              <li><Link to="/foretagslan">Företagslån</Link></li>
              <li><Link to="/snabblan">Snabblån</Link></li>
              {/* <li><Link to="/kreditkort">Kreditkort</Link></li> */}
            </ul>
          </div>
          <div className="col-lg-4 col-sm-6">
            <h5>Lär dig mer</h5>
            <ul className="link-col">
              <li><Link to="/allt-om-lan">Allt om lån</Link></li>
              {/* <li><Link to="">FAQ</Link></li> */}
            </ul>
          </div>
          {/* <div className="col-lg-4 col-sm-6">
            <h5>Kontakt</h5>
            <ul className="link-col">
              <li className="mb-2">
                <i className="fas fa-phone me-3"></i>
                <Link to="">1234-123 123</Link>
              </li>
              <li className="mb-2">
                <i className="fas fa-envelope me-3"></i>
                <Link to="">test@test.com</Link>
              </li>
            </ul>
          </div> */}
          <div className="col-lg-4 col-sm-6">
            <h5>Om Lånbyte</h5>
            <ul className="link-col">
              {/* <li><Link to="">Om oss</Link></li> */}
              {/* <li><Link to="">Våra partners</Link></li> */}
              <li><Link to="/villkor">Om oss</Link></li>
              <li><Link to="/sekretesspolicy">Sekretesspolicy</Link></li>
            </ul>
          </div>
        </div>

        <hr />

        <div className="text-end footer-bottom">
          © 2021-2022 Lånbyte i Sverige AB, org nr: 559209-5730
        </div>
      </div>
    </div>
  );
}

export default Footer;
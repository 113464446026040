import React from 'react';
import { Link, NavLink } from "react-router-dom";
import image from "../../assets/logo.png";

import "./Header.scss";

function Header() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark" id="header">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/"><img src={image} height={50} width={176} alt="Lånbyte" /></Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-label="collapse">
          <span className="text-white"><i className="fa fa-bars fa-lg p-2"></i></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/privatlan">Privatlån</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/snabblan">Snabblån</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/foretagslan">Företagslån</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
import { lazy } from 'react';

export const Pages = {
  Page404: lazy(() => import("./Page404/Page404")),
  Home: lazy(() => import("./PageHome/PageHome")),

  // Compare: lazy(() => import("./PageCompare/PageCompare")),
  PrivateLoan: lazy(() => import("./PagePrivateLoan/PagePrivateLoan")),
  QuickLoan: lazy(() => import("./PageQuickLoan/PageQuickLoan")),
  BusinessLoan: lazy(() => import("./PageBusinessLoan/PageBusinessLoan")),

  LoanInformation: lazy(() => import("./Documents/LoanInformation")),
  Terms: lazy(() => import("./Documents/Terms")),
  Privacy: lazy(() => import("./Documents/Privacy")),
  Partners: lazy(() => import("./PagePartners/PagePartners")),

  Article1: lazy(() => import("./Articles/article1")),
  Article2: lazy(() => import("./Articles/article2")),
  Article3: lazy(() => import("./Articles/article3")),
  Article4: lazy(() => import("./Articles/article4")),
  Article5: lazy(() => import("./Articles/article5")),
  Article6: lazy(() => import("./Articles/article6")),
  Article7: lazy(() => import("./Articles/article7")),
  Article8: lazy(() => import("./Articles/article8")),
};

export const pageStructure = {
  "": { component: Pages.Page404 },
  "/": { component: Pages.Home },
  "/privatlan": {
    params: [
      { key: "amount", optional: true },
      { key: "duration", optional: true },
      { key: "best", optional: true }
    ], component: Pages.PrivateLoan
  },
  "/snabblan": {
    params: [
      { key: "amount", optional: true },
      { key: "duration", optional: true },
      { key: "best", optional: true }
    ], component: Pages.QuickLoan
  },
  "/foretagslan": {
    params: [
      { key: "amount", optional: true },
      { key: "duration", optional: true },
      { key: "best", optional: true }
    ], component: Pages.BusinessLoan
  },
  "/villkor": { component: Pages.Terms },
  "/allt-om-lan": { component: Pages.LoanInformation },
  "/sekretesspolicy": { component: Pages.Privacy },

  "/article1": { component: Pages.Article1 },
  "/article2": { component: Pages.Article2 },
  "/article3": { component: Pages.Article3 },
  "/article4": { component: Pages.Article4 },
  "/article5": { component: Pages.Article5 },
  "/article6": { component: Pages.Article6 },
  "/article7": { component: Pages.Article7 },
  "/article8": { component: Pages.Article8 },
  // "/partners": { component: Pages.Partners },
};

export default pageStructure;
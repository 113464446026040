import { Switch, Route } from "react-router-dom";

function SwitchController(props) {
  const traverseObject = (object, keyPrev = "", _keyList = {}) => {
    let keyList = _keyList;

    for (let key in object) {
      let keyFull = keyPrev + key;
      let item = object[key];

      item.exact = item.params ? false : true;
      keyList[keyFull] = item;

      if (item.children) {
        traverseObject(item.children, keyFull, keyList);
      }
    }

    if (!keyPrev) return keyList;
  }

  const paramsToURL = (params) => {
    return params.map(param => {
      if (param.optional) return `/:${param.key}?`;
      else return `/:${param.key}`;
    }).join("");
  }

  let structure = props.structure;
  let pages = traverseObject(structure);
  let routes = [];
  let route404 = null;

  for (let route in pages) {
    let page = pages[route];

    if (!page.component) {
      continue;
    } else if (route === "") {
      route404 = page.component;
      continue;
    }

    let path = route + paramsToURL(page.params || []);
    let component = page.component;

    routes.push(
      <Route path={path} exact={page.exact || false} component={component} key={path} />
    );
  }

  if (route404) {
    routes.push(
      <Route path="" component={route404} key="page404" />
    );
  }

  return (
    <Switch>{routes}</Switch>
  )
}

export default SwitchController;